<template>
	<div class="team">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
			<el-breadcrumb-item>团队详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="teamDetaial">
			<div class="topType">
				<span class="text">团队详情</span>
			</div>
			<div class="incomeList">
				<el-table :data="teamData">
					<el-table-column prop="id" label="用户编号" align="center"></el-table-column>
					<el-table-column label="姓名" align="center">
						<template slot-scope="scope">
						    <span v-if="scope.row.profile.truename != ''">{{scope.row.profile.truename}}</span>
						</template>
					</el-table-column>
		            <el-table-column prop="passport" label="账号" align="center"></el-table-column>
				</el-table>
				<div class="block">
				    <el-pagination  
					@current-change="handleCurrentChange" 
					layout="prev, pager, next, jumper" 
					:page-size="page.pagesize"
					:current-page="page.currentPage"
					:total="page.total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Distribution
	} from "@/api/index";
	export default {
		data() {
			return {
				teamData: [],
				page: {
					total:0,
					pagesize:10,
					currentPage: 1,
				},
			}
		},
		created() {
			this.teamDetail()
		},
		methods: {
			async teamDetail(page) {
				let {
					status,
					data
				} = await Distribution.team({page:page});
				if (status) {
					if (data.code == 0) {
						this.teamData =[]
						this.teamData.push(...data.data.list);
						this.page.total = data.data.total;
						this.page.currentPage = data.data.currentPage;
					}
				}
			},
			//分页
			async handleCurrentChange(val) {
				this.page.currentPage = val;
				this.teamDetail(this.page.currentPage)
			},
		}
	}
</script>

<style lang="less">
	.team {
		.el-breadcrumb {
			padding: 30px;

			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}

			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}

		.teamDetaial {
			background-color: #fff;
			margin: 20px;
			border-radius: 10px;

			.topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;

				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}

			.topType::before {
				content: "";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}

			.incomeList {
				padding: 10px;
			}
			.el-pagination {
				text-align: right;
				padding: 10px 10px 0 0;
			}
		}
	}
</style>
